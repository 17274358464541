<template>
    <div class="user">
      <!-- 面包屑导航 -->
      <el-card>
        <!-- 头 -->
        <el-row :gutter="20">
          <el-col :span="5">
            <el-input placeholder="请输入内容" v-model="getInfo.info">
              <el-button slot="append" icon="el-icon-search"></el-button>
            </el-input>
          </el-col>
          <el-col :span="8"> </el-col>
          <el-tooltip
            class="item"
            effect="dark"
            content="增加路线"
            placement="top-start"
          >
            <el-button type="primary" @click="addPeoBtn">增加路线</el-button>
          </el-tooltip>
          <el-col :span="2">
        <div style="height:40px;width:80px;line-height: 40px;">分类类型</div>
        </el-col>
        <el-col :span="4">
            <el-select @change="onChangeClassList" v-model="form.classification_id" placeholder="请选择">
                <el-option
                v-for="item in classification_Arr"
                :key="item.classification_id"
                :label="item.classification_name"
                :value="item.classification_id">
                </el-option>
            </el-select>
        </el-col>
        </el-row>
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          v-if="tableData"
        >
          <el-table-column align="center" prop="route_name" label="路线名称">
          </el-table-column>
          <el-table-column prop="url" label="封面图">
            <template slot-scope="scope">
              <div class="image">
                <img
                  :src="scope.row.route_cover_image"
                  alt=""
                  width="120"
                  v-if="scope.row.route_cover_image"
                />
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="early_days" label="提前报名天数">
          </el-table-column>
          <!-- <el-table-column prop="url" label="图集">
            <template slot-scope="scope">
              <div v-if="scope.row.route_image_json">
              <div class="image" v-for="(item,i) in JSON.parse(scope.row.route_image_json)" :key="item+i">
                <img
                  :src="item"
                  alt=""
                  width="120"
                />
              </div>
          </div>
            </template>
          </el-table-column> -->
          <el-table-column prop="status" label="状态">
            <template slot-scope="scope">
              <el-switch
                @change="changeState(scope.row.route_id, scope.row.route_status)"
                v-model="scope.row.route_status"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="1"
                :inactive-value="-1"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
        
              <el-tooltip
                class="item"
                effect="dark"
                content="查看路线"
                placement="top-start"
              >
                <el-button
                  icon="el-icon-search"
                  @click="lookPicture(scope.row)"
                ></el-button>
              </el-tooltip>
          
              <el-tooltip
                class="item"
                effect="dark"
                content="修改路线"
                placement="top-start"
              >
                <el-button
                  icon="el-icon-edit"
                  type="primary"
                  @click="updataPicture(scope.row)"
                ></el-button>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                content="删除路线"
                placement="top-start"
              >
                <el-button
                  icon="el-icon-delete"
                  type="danger"
                  @click="delPicture(scope.row.route_id)"
                ></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          @current-change="pageChange"
          @size-change="sizeChange"
          :page-sizes="[7, 15, 20]"
          layout="total, sizes, prev, pager, next"
          :page-size="this.getInfo.page_num"
          background
          :total="exp.num"
        >
        </el-pagination>
      </el-card>
      <!-- 增加路线对话框 -->
      <el-dialog
        :visible.sync="dialogFormVisible"
        @close="userClose(form)"
        :append-to-body="true"
      >
        <div slot="title" v-if="updataFalg" class="dialog-footer">
          <div class="title">修改路线</div>
        </div>
        <div slot="title" v-else-if="lookFalg" class="dialog-footer">
          <div class="title">查看路线</div>
        </div>
        <div slot="title" v-else class="dialog-footer">
          <div class="title">增加路线</div>
        </div>
        <div class="diaBox">
      
          <el-form :model="form" ref="ruleForm" :rules="rules">
            <el-form-item
            label="路线分类"
            :label-width="formLabelWidth"
          >
          <el-select @change="onChangeClassList1" v-model="form.classification_id" placeholder="请选择">
                <el-option
                v-for="item in classification_Arr"
                :key="item.classification_id"
                :label="item.classification_name"
                :value="item.classification_id">
                </el-option>
            </el-select>
          </el-form-item>
            <el-form-item
              label="路线名称"
              :label-width="formLabelWidth"
              prop="route_name"
            >
              <el-input v-model="form.route_name"></el-input>
            </el-form-item>
            <el-form-item
              label="提前天数"
              :label-width="formLabelWidth"
            >
              <el-input type="number" v-model="form.early_days"></el-input>
            </el-form-item>
            <el-form-item
              label="封面图"
              prop="site_cover_image"
              :label-width="formLabelWidth"
            >
              <el-upload
                class="upload-demo"
                :action="imgUrl"
                :on-success="imgSuccess"
                :on-remove="imgRemove"
                :on-exceed="exceed"
                :file-list="fileList"
                :data="imgParam"
                :limit="maxLimit"
                list-type="picture"
              >
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
            </el-form-item>
            <el-form-item
              label="图集"
              prop="site_cover_image"
              :label-width="formLabelWidth"
            >
              <el-upload
                class="upload-demo"
                :action="imgUrl"
                :on-success="imgSuccessArr"
                :on-remove="imgRemoveArr"
                :on-exceed="exceedArr"
                :file-list="fileListArr"
                :data="imgParam"
                :limit="maxLimitArr"
                list-type="picture"
              >
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
            </el-form-item>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button v-if="addFlag" type="primary" @click="addPath('ruleForm')"
            >添 加</el-button
          >
          <el-button
            v-else-if="lookFalg"
            type="primary"
            @click="dialogFormVisible = false"
            >确 认</el-button
          >
          <el-button v-else type="primary" @click="updSiteSer('ruleForm')"
            >修 改</el-button
          >
        </div>
      </el-dialog>
    </div>
  </template>
    
    <script>
  //这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
  //例如：import 《组件名称》 from '《组件路径》';
  import {
    PostRouteList,
    PostRouteDetail,
    PostRouteUpdate,
    PostClassList
  } from "@/api/routeList/routeList.js";
  export default {
    //import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
      var validateRoute_name = (rule, value, callback) => {
        if (this.form.route_name === "") {
          callback(new Error("请输入路线名称"));
        } else {
          callback();
        }
      };
      var validateSite_cover_image = (rule, value, callback) => {
        if (this.form.route_cover_image === "") {
          callback(new Error("请上传封面图"));
        } else {
          callback();
        }
      };
      var validateSite_image_json = (rule, value, callback) => {
        if (this.form.route_image_json.length === 0) {
          callback(new Error("请上传图片集"));
        } else {
          callback();
        }
      };
      //这里存放数据
      return {
        rules: {
          route_name: [{ validator: validateRoute_name, trigger: "blur" }],
          site_cover_image: [
            { validator: validateSite_cover_image, trigger: "blur" },
          ],
          site_image_json: [
            { validator: validateSite_image_json, trigger: "blur" },
          ],
        },
        classification_Arr:[],
        maxLimit: 1,
        maxLimitArr: 9,
        //总条数
        exp: {
          count: Number,
          num: 0,
        },
        //当前页码
        getInfo: {
          //搜索框
          info: "",
          page_code: 1,
          page_num: 15,
        },
        form: {
          classification_id:'',
          early_days:'',
          route_id:'',
          route_name: "",
          route_cover_image: "",
          route_image_json: [],
        },
        imgArrList: [], //图片集临时数组
        props: {
          children: "sub",
          value: "id",
          label: "name",
        },
        //表格数据
        tableData: [],
        token: {
          token: window.sessionStorage.getItem("token"),
        },
        formLabelWidth: "100px",
        imgParam: {
          path: "contract/template",
        },
        fileList: [],
        fileListArr: [],
        //控制弹窗--新增修改
        dialogFormVisible: false,
        addFlag: false,
        lookFalg: false,
        updataFalg: false,
      };
    },
    //监听属性 类似于data概念
    computed: {
      imgUrl() {
        return this.$store.state.imgUrl;
      },
    },
    //监控data中的数据变化
    watch: {
      dialogFormVisible(newVal, oldVal) {
        if (!newVal) {
          this.form = this.$options.data().form;
          this.fileList=[];
          this.fileListArr=[];
          this.imgArrList=[];
          this.updataFalg = false;
          this.lookFalg = false;
          this.addFlag = false;
        }
      },
    },
    //方法集合
    methods: {
      onChangeClassList1(value){
        this.form.classification_id=value
      },
        //分类列表下拉框更新
        onChangeClassList(value){
      var data = {
        page: this.getInfo.page_code,
        page_num: this.getInfo.page_num,
        classification_id:value
      };
      this.PostRouteList(data);
      },
      //查看详情
      lookPicture(item){
        this.$router.push({
            name:'routeDetail',
            params:{
              route_id:item.route_id
            }
        })
        // this.dialogFormVisible=true;
        //  this.lookFalg=true;
        //  var arr=JSON.parse(item.route_image_json)
        //  this.form={
        //   classification_id:item.classification_id,
        //   early_days:item.early_days,
        //   route_id:item.route_id,
        //   route_name: item.route_name,
        //   route_cover_image: item.route_cover_image,
        //   route_image_json: item.route_image_json,
        //  }
        //  this.fileList.push({
        //     name: item.title, url: item.route_cover_image
        //   })
        //  arr.forEach((item)=>{
        //   this.fileListArr.push({
        //     name: item.title, url: item
        //   })
        //  })
      },
      //修改路线按钮
      updataPicture(item){
          console.log(item);
         this.dialogFormVisible=true;
         this.updataFalg=true;
         this.form={
          classification_id:item.classification_id,
          early_days:item.early_days,
          route_id:item.route_id,
          route_name: item.route_name,
          route_cover_image: item.route_cover_image,
          route_image_json: item.route_image_json,
         }
         this.fileList = [{ name: item.title, url: item.route_cover_image }];
         var objArr=[];
         var routeImageArr=JSON.parse(item.route_image_json);
         routeImageArr.forEach((item)=>{
          this.fileListArr.push({ name: item.title, url: item })
         })
         //this.fileListArr = [{ name: item.title, url: JSON.parse(item.route_image_json) }];
      },
      //修改路线dia按钮
      updSiteSer(formName){
          this.$refs[formName].validate((valid) => {
          if (valid) {
            //this.PostSite_addData(this.form);
            if(Array.isArray(this.form.route_image_json)){
              this.form.route_image_json=JSON.stringify(this.form.route_image_json)
            }
            var dataObj={ 
              early_days:this.form.early_days,             
              classification_id:this.form.classification_id,
              route_id:this.form.route_id,
              route_name:this.form.route_name,
              route_cover_image:this.form.route_cover_image,
              route_image_json:this.form.route_image_json
            }
           console.log(this.form)
            this.PostRouteUpdate(dataObj)
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      },
       //增加路线dia按钮
      addPath(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            //this.PostSite_addData(this.form);
            var dataObj={
              early_days:this.form.early_days,   
              classification_id:this.form.classification_id,
              route_name:this.form.route_name,
              route_cover_image:this.form.route_cover_image,
              route_image_json:JSON.stringify(this.form.route_image_json)
            }
           console.log(this.form)
            this.PostRouteAdd(dataObj)
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      },
      //封面图片导入事件
      imgSuccess(response, file, fileList) {
        console.log(response);
        this.form.site_cover_image = response.result.url;
      },
      //图片集导入事件
      imgSuccessArr(response, file, fileList) {
      //  this.imgArrList.push(response.result.url);
      let arr = JSON.parse(this.form.route_image_json);
      arr.push(response.result.url)
     // console.log(arr)
      this.form.route_image_json = JSON.stringify(arr);
      },
      //移除封面图片的事件
      imgRemove(file, fileList) {
        this.form.route_cover_image = "";
      },
       //移除图片集的事件
       imgRemoveArr(file, fileList) {
       // console.log(file)  
        let arr = JSON.parse(this.form.route_image_json);
       // console.log(arr,arr.indexOf(file.url))
        arr.splice(
          arr.indexOf(file.url),1
        );
        this.form.route_image_json=JSON.stringify(arr);
      },
       //封面图片数量超出事件
       exceed() {
        this.$message({
          message: "数量超出",
          type: "warning",
        });
      },
      //图片集数量超出事件
      exceedArr() {
        this.$message({
          message: "数量超出",
          type: "warning",
        });
      },
      //增加路线
      addPeoBtn() {
        this.dialogFormVisible = true;
        this.addFlag=true;
      },
      //删除路线
      delPicture(route_id) {
        this.$confirm("此操作将删除该数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            var objData = {
              route_id: route_id,
            };
            this.PostRouteDelete(objData);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      },
      //swith状态事件
      async changeState(id, status) {
        console.log(id, status);
        var data = {
          route_id: id,
          route_status: status,
        };
        let res = await PostRouteShelf(data);
        if (res.data.status) {
          this.$message({
            type: "success",
            message: "更新成功!",
          });
          var data = {
            page: this.getInfo.page_code,
            page_num: this.getInfo.page_num,
          };
          this.PostRouteList(data);
        } else {
          this.$message.error("错了哦，这是一条错误消息");
        }
      },
      //修改每页数量
      sizeChange(value) {
        this.getInfo.page_num = value;
        var data = {
        page: this.getInfo.page_code,
        page_num: this.getInfo.page_num,
      };
      this.tableData = [];
      this.PostRouteList(data);
      },
      //分页切换
      pageChange(value) {
        this.getInfo.page_code = value;
        var data = {
        page: this.getInfo.page_code,
        page_num: this.getInfo.page_num,
      };
      this.tableData = [];
      this.PostRouteList(data);
      },
      exceed() {
        this.$message({
          message: "数量超出",
          type: "warning",
        });
      },
      //导入图片事件
      imgSuccess(response, file, fileList) {
        console.log(response);
        this.form.route_cover_image = response.result.url;
      },
  
      //移除图片的事件
      imgRemove(file, fileList) {
        this.form.route_cover_image = "";
      },
      //添加事件 退出弹窗
      userClose(e) {
        this.form = {
          route_cover_image: "", //图片
        };
        (this.fileList = []), console.log("close");
      },
      /**-----------------网络请求生命周期函数--------------------- */
        //分类
    async PostClassList(dataObj){
         let res=await PostClassList(dataObj);
         if(res.data.status){
            this.$message({
          type: "success",
          message: res.data.message,
        });
        var arr=[]
        arr=res.data.result.data.filter((item)=>{
          return item.classification_name!="热门"
        })
        this.dialogFormVisible=false;
        this.classification_Arr=arr;
        // this.form.classification_id=res.data.result.data[0].classification_id
        var data = {
      page: this.getInfo.page_code,
      page_num: this.getInfo.page_num
    };
    this.PostRouteList(data);
        }else{
            this.$message.error( res.data.message);
        }
        console.log(res.data);
    },
      //修改路线
      async PostRouteUpdate(dataObj){
          let res=await PostRouteUpdate(dataObj);
          if(res.data.status){
              this.$message({
            type: "success",
            message: res.data.message,
          });
          this.dialogFormVisible=false;
          var data = {
        page: this.getInfo.page_code,
        page_num: this.getInfo.page_num,
      };
      this.PostRouteList(data);
          }else{
              this.$message.error( res.data.message);
          }
          console.log(res.data);
      },
      //增加路线
      async PostRouteAdd(dataObj){
          let res=await PostRouteAdd(dataObj);
          if(res.data.status){
          //     this.$message({
          //   type: "success",
          //   message: res.data.message,
          // });
          this.dialogFormVisible=false;
          var data = {
        page: this.getInfo.page_code,
        page_num: this.getInfo.page_num,
        classification_id:this.form.classification_id,
      };
      //this.PostRouteList(data);
          }else{
              this.$message.error( res.data.message);
          }
          console.log(res.data);
      },
      //路线列表
      async PostRouteList(data) {
        let res = await PostRouteList(data);
        this.tableData = res.data.result.data;
        this.getInfo = {
          page_code: res.data.result.last_page,
          list_rows: res.data.result.per_page,
        };
        this.exp.num = res.data.result.total;
      },
      async PostRouteDelete(objData) {
        let res = await PostRouteDelete(objData);
        if (res.data.status) {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          var data = {
            page: this.getInfo.page_code,
            page_num: this.getInfo.page_num,
          };
          this.PostRouteList(data);
        } else {
          this.$message({
            type: "success",
            message: res.data.message,
          });
        }
      },
    },
    beforeCreate() {}, //生命周期 - 创建之前
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
   
    var data = {
      page: this.getInfo.page_code,
      list_rows: this.getInfo.page_num,
    };
    this.PostClassList(data);
      this.PostRouteList(data);
    },
  
    beforeMount() {}, //生命周期 - 挂载之前
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {},
    beforeUpdate() {}, //生命周期 - 更新之前
    updated() {}, //生命周期 - 更新之后
    activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    beforeDestroy() {}, //生命周期 - 销毁之前
    destroyed() {}, //生命周期 - 销毁完成
  };
  </script>
    
    <style scoped>
  .page {
    margin-top: 20px;
  }
  .title {
    font-size: 18px;
    font-weight: bold;
  }
  .set-page {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .set-page-left {
    color: #0000006d;
    font-size: 14px;
    font-weight: 400;
  }
  .title {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
  .diaBox {
    display: flex;
    justify-content: center;
  }
  .imgUrl {
    margin-left: 50%;
    transform: translateX(-50%);
    width: 250px;
    height: 150px;
    overflow: hidden;
    text-align: center;
  }
  /* .image {
    width: 120px;
    height: 120px;
    border-radius: 5px;
    display: flex;
    justify-items: center;
    align-items: center;
    background: #c0c4cc;
    overflow: hidden;
  } */
  </style>