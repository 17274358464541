import { post } from '@/utils/request'

//路线列表
export function PostRouteList(data) {
  return post('adminapi/route_list', data)
}
//路线详情
export function PostRouteDetail(data) {
  return post('adminapi/route_detail', data)
}
// //路线上下架
// export function PostRouteShelf(data) {
//     return post('adminapi/route_shelf',data)
//   }
//   //路线删除
// export function PostRouteDelete(data) {
//     return post('adminapi/route_delete',data)
//   }
//路线增加
export function PostRouteAdd(data) {
  return post('adminapi/route_add', data)
}
//路线更新
export function PostRouteUpdate(data) {
  return post('adminapi/route_update', data)
}
//路线分类
export function PostClassList(data) {
  return post('adminapi/classification_list', data)
}
//修改路线子项
export function PostrouteSubUpdate(data) {
  return post('adminapi/route_subprojects_update', data)
}
//删除路线子项
export function PostrouteSubDelete(data) {
  return post('adminapi/route_subprojects_delete', data)
}
//站点服务
export function PostServiceList(data) {
  return post('adminapi/site_service_list', data)
}